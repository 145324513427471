export default [
  {
    name: 'myanmar-gold',
    type: 'hardcover',
    label: 'Myanmar Gold',
    price: 21.99,
    weightOz: 24,
  },
  {
    name: 'myanmar-gold',
    type: 'audiobook',
    label: 'Myanmar Gold',
    price: 19.99,
    weightOz: 0,
  },
  {
    name: 'myanmar-gold',
    type: 'ebook',
    label: 'Myanmar Gold',
    preorder: true,
    price: 12.99,
    weightOz: 0,
  },
]
