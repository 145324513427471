import React from 'react'
import { CheckboxInput } from '@foundation-lib/ui'
import { useCartItemList } from '@foundations-of-grace-pub/checkout'
import SEO from '../components/Seo'
import BaseLayout from '../components/layouts/BaseLayout'
import Container from '../components/layouts/Container'
import availableItems from '../data/items'

const typeLabels = {
  ebook: 'eBook',
  hardcover: 'Hardcover',
  paperback: 'Paperback',
  audiobook: 'AudioBook',
}

export default () => {
  const sendCartItemListAction = useCartItemList(s => s.action)
  const getItem = useCartItemList(s => s.getItem)

  return (
    <BaseLayout>
      <SEO title="Purchase" />
      <Container>
        {availableItems.map(item => (
          <div>
            <h3>{typeLabels[item.type]}</h3>
            <CheckboxInput
              label={typeLabels[item.type]}
              checked={!!getItem(item)}
              onClick={e => {
                sendCartItemListAction({
                  action: e.target.checked ? 'add' : 'remove',
                  item: {
                    ...item,
                    qty: 1,
                  },
                })
              }}
            />
          </div>
        ))}
      </Container>
    </BaseLayout>
  )
}
